<template>
  <v-container class="d-flex flex-column pa-8">
    <div class="header-top">
      <h2 class="view-title">Report A Bug</h2>
    </div>
    <v-card>
      <v-card-text>
        <v-form ref="form" class="form-report">
          <v-text-field
            v-model="location"
            label="Where was this bug found? (URL) *"
            outlined
            placeholder="Where was this bug found? (URL)"
            hint="Where was this bug found? (URL)"
            persistent-hint
            hide-details="auto"
            :rules="[validators.urlValidator]"
          />
          <v-textarea
            v-model="details"
            outlined
            label="Details *"
            placeholder="Details"
            hint="Details"
            persistent-hint
            hide-details="auto"
            :rules="[validators.required]"
          />
          <label class="attach-text">Attach files</label>
          <vue-dropzone
            ref="rbDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            @vdropzone-success="dropzoneOnSuccess"
            @vdropzone-upload-progress="dropzoneUploadProgress"
            @vdropzone-file-added="dropzoneFileAdded"
            @vdropzone-complete="dropzoneComplete"
            @vdropzone-canceled="dropzoneCanceled"
          />
          <v-text-field
            v-model="device"
            label="Device Used *"
            outlined
            placeholder="Device Used"
            hint="Device Used"
            persistent-hint
            hide-details="auto"
            :rules="[validators.required]"
          />
          <v-select
            v-model="browser"
            :items="browsers"
            label="Select Browser"
            item-text="name"
            item-value="value"
            outlined
          />
          <v-select
            v-model="os"
            :items="os_list"
            label="Select Operating System"
            item-text="name"
            item-value="value"
            outlined
          />

          <v-alert v-if="errors && errors.length > 0" class="mt-4" color="error" text>
            <p class="font-weight-semibold mb-1">Messages</p>
            <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
              {{ error }}
            </p>
          </v-alert>

          <v-btn color="primary" class="btn-submit" @click="submit" :disabled="loading">
            <span v-if="loading === false">Submit</span>
            <span v-else>
              <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
              <span>Sending request...</span>
            </span>
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>


<script>
import { mapActions } from 'vuex'
import { mdiClose } from '@mdi/js'
import { required, urlValidator } from '@core/utils/validation'
//Dropzone
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      form: null,
      data: {},
      loading: false,
      validators: {
        required,
        urlValidator,
      },

      location: '',
      details: '',
      device: '',
      url: '',
      browser: '',
      os: '',
      files: [],

      // errors
      message: '',
      errors: [],

      dropzoneOptions: {
        url: process.env.VUE_APP_BACKEND_URL + '/api' + '/file/upload',
        addRemoveLinks: true,
        maxFiles: 5,
        acceptedFiles: '.jpeg,.jpg,.png,.gif,.pdf',
        maxFilesize: 12,
        timeout: 0,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.accessToken}`,
        },
      },

      dropzoneTotalFilesize: 0,
      dropzoneUploadedFilesize: 0,
      dropzoneCurrentUpload: 0,

      browsers: [
        { name: 'Google Chrome', value: 'Google Chrome' },
        { name: 'Internet Explorer', value: 'Internet Explorer' },
        { name: 'Opera', value: 'Opera' },
        { name: 'Safari', value: 'Safari' },
        { name: 'Firefox', value: 'Firefox' },
        { name: 'Other', value: 'Other' },
      ],
      os_list: [
        { name: 'MacOS', value: 'MacOS' },
        { name: 'Window', value: 'Window' },
        { name: 'Linux', value: 'Linux' },
        { name: 'Android', value: 'Android' },
        { name: 'iOS', value: 'iOS' },
        { name: 'Other', value: 'Other' },
      ],
    }
  },
  computed: {
    uploadProgress() {
      return Math.round(
        ((this.dropzoneUploadedFilesize + this.dropzoneCurrentUpload) / this.dropzoneTotalFilesize) * 100,
      )
    },
  },
  created() {
    this.browser = this.detectBrowser()
    this.os = this.detectOS()
    this.device = this.getDeviceType()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/set',
      create: 'report_bug/create',
    }),
    async submit() {
      if (this.$refs.form.validate()) {
        const form_data = {
          location: this.location,
          details: this.details,
          device: this.device,
          browser: this.browser,
          os: this.os,
          file_uploads: this.files,
        }

        this.loading = true
        await this.create(form_data)
          .then(resp => {
            if (resp && resp.data) {
              if (resp.data.status == 'ok') {
                this.setSnackbar({
                  status: true,
                  text: 'The application was sent successfully.',
                  color: 'primary',
                })

                this.errors = null
                this.cleanForm()
              }
            }
          })
          .catch(error => {
            this.setSnackbar({
              status: true,
              text: 'The request could not be submitted.',
              color: 'error',
            })

            if (error.response.data && error.response.data.status === 'error') {
              this.errors = error.response.data.errors
            } else {
              this.errors = null
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    dropzoneFileAdded(file) {
      this.dropzoneTotalFilesize += file.size
    },
    dropzoneUploadProgress(file, totalBytes, totalBytesSent) {
      this.dropzoneCurrentUpload = totalBytesSent // write totalBytes to dropzoneCurrentUpload
      if (file.size <= totalBytesSent) {
        this.dropzoneCurrentUpload = 0 // reset current upload bytes counter
        this.dropzoneUploadedFilesize += totalBytesSent // add finished file to total upload
      }
    },
    dropzoneOnSuccess(file, success) {
      const response = JSON.parse(file.xhr.responseText)
      this.files.push(response.file_id)
    },
    dropzoneComplete(file) {},
    dropzoneCanceled(file) {},
    dropzoneError(file, message, xhr) {},
    detectBrowser() {
      if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) != -1) {
        return 'Opera'
      } else if (navigator.userAgent.indexOf('Chrome') != -1) {
        return 'Google Chrome'
      } else if (navigator.userAgent.indexOf('Safari') != -1) {
        return 'Safari'
      } else if (navigator.userAgent.indexOf('Firefox') != -1) {
        return 'Firefox'
      } else if (navigator.userAgent.indexOf('MSIE') != -1 || !!document.documentMode == true) {
        return 'Internet Explorer'
      } else {
        return 'Other'
      }
    },
    detectOS() {
      if (navigator.userAgent.indexOf('Win') != -1) {
        return 'Windows'
      } else if (navigator.userAgent.indexOf('Mac') != -1) {
        return 'MacOs'
      } else if (navigator.userAgent.indexOf('Linux') != -1) {
        return 'Linux'
      } else if (navigator.userAgent.indexOf('Android') != -1) {
        return 'Android'
      } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        return 'iOS'
      } else {
        return 'Other'
      }
    },
    getDeviceType() {
      const ua = navigator.userAgent
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'Tablet'
      }
      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
      ) {
        return 'Mobile'
      }
      return 'Desktop'
    },
    async cleanForm() {
      await this.$refs.form.reset()
      this.$refs.rbDropzone.removeAllFiles()
      this.files = []
      this.browser = this.detectBrowser()
      this.os = this.detectOS()
      this.device = this.getDeviceType()
    },
  },
}
</script>

<style scoped>
.header-top {
  margin-bottom: 20px;
}
.btn-more {
  padding: 0px !important;
  word-break: break-word !important;
}
.btn-submit {
  margin-top: 20px;
}

.attach-text {
  margin: 10px 10px;
  font-size: 12px;
}
.vue-dropzone {
  background: transparent;
}
.dropzone {
  background: transparent;
  border: 1px solid #595572;
  border-radius: 10px;
  margin-bottom: 20px;
  color: #aca8c3;
}
</style>
